import { useEffect, useRef } from 'react';
import Marquee from 'react-light-marquee';
import { useSelector } from 'react-redux';

import type { TAggregatedCountriesProps } from 'Components/common/aggregatedCountries/interface';
import {
	CountryFlagItem,
	CountryFlagsContainer,
	TopBorder,
	WrapperContainer,
} from 'Components/common/aggregatedCountries/style';
import Conditional from 'Components/common/conditional';
import DangerousHTML from 'Components/common/dangerousHTML';
import Image from 'Components/common/image';

import { useAutoPlayingLottieAnimation } from 'Hooks/useAutoPlayingLottieAnimation';
import useOnScreen from 'Hooks/useOnScreen';
import { getCountryFlagUrl } from 'Utils/imageUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getDeviceType } from 'Utils/stateUtils';

import { DEVICE_TYPE, HEARTS_LOTTIE_URL } from 'Constants/constants';
import { strings } from 'Constants/strings';

const AggregatedCountries = ({
	slideAnimation,
	lang,
	reviewCountries,
}: TAggregatedCountriesProps) => {
	const lottieContainerRef = useRef<HTMLDivElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const isMobile =
		useSelector(state => getDeviceType(state)) === DEVICE_TYPE.MOBILE;

	const { countries, count } = reviewCountries;
	const countryFlagUrls = countries.map(({ code }) =>
		getCountryFlagUrl(code),
	);
	const mainCountries = countries
		.slice(0, 3)
		.map(({ displayName }) => displayName)
		.join(', ');

	useAutoPlayingLottieAnimation({
		ref: lottieContainerRef,
		animationProperties: { loop: true, path: HEARTS_LOTTIE_URL },
	});

	const isOnScreen = useOnScreen({
		ref: wrapperRef,
		options: { threshold: PlatformUtils.isDesktop() ? 0.9 : 0.6 },
	});

	useEffect(() => {
		if (!!slideAnimation && wrapperRef.current) {
			const observer = new IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					setTimeout(() => {
						wrapperRef.current?.classList.add('loaded');
					}, 1000);
				}
			});

			observer.observe(wrapperRef.current!);

			return () => observer.disconnect();
		}
	}, [slideAnimation]);

	return (
		<>
			<Conditional if={isMobile}>
				<TopBorder />
			</Conditional>

			<WrapperContainer
				ref={wrapperRef}
				$hasSlideAnimation={slideAnimation}
				className={`lang-${lang} review-banner`}
			>
				<CountryFlagsContainer className={`country-flags lang-${lang}`}>
					<Marquee
						id='countries'
						direction='up'
						speed={20}
						play={isOnScreen}
					>
						{countryFlagUrls.map((url, index) => (
							<CountryFlagItem
								key={countryFlagUrls.length + index}
							>
								<Image
									className='country-flag'
									src={url}
									alt={
										countries[index].displayName ??
										'country flag'
									}
									height={22.5}
									width={30}
								/>
							</CountryFlagItem>
						))}
					</Marquee>
				</CountryFlagsContainer>

				<DangerousHTML
					className='guest-text'
					html={strings.formatString(
						strings.PPRS_AGGREGATED_COUNTRIES,
						mainCountries,
						count,
					)}
				/>

				<div className='hearts-lottie' ref={lottieContainerRef} />
			</WrapperContainer>
		</>
	);
};

export default AggregatedCountries;
