import React from 'react';

type Props = {
	html?: string;
	className?: string;
};

class DangerousHTML extends React.Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		return (
			nextProps.html !== this.props.html ||
			nextProps.className !== this.props.className
		);
	}
	render() {
		const html = this.props.html;
		return html ? (
			<div
				className={this.props.className || ''}
				dangerouslySetInnerHTML={{ __html: this.props.html }}
			/>
		) : null;
	}
}

export default DangerousHTML;
